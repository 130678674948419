import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M1989 3974 c-10 -12 -22 -205 -51 -798 -43 -907 -44 -939 -23 -956
23 -19 1029 30 1049 51 11 10 16 67 21 220 l7 207 -25 17 c-23 15 -57 16 -306
13 -244 -3 -282 -1 -296 13 -14 14 -14 44 1 333 13 253 14 320 4 326 -10 6
-10 8 0 12 17 5 45 512 31 544 -12 28 -52 34 -242 34 -132 0 -159 -3 -170 -16z
m141 -1153 c0 -10 -50 -25 -57 -17 -3 2 -2 9 2 15 8 13 55 15 55 2z m400 -271
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-360 -50 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m82 -132 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3 -1
-11 -7 -17z m273 -8 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10
5 0 12 -4 15 -10z"/>
<path d="M1630 1855 l0 -225 45 0 45 0 0 225 0 225 -45 0 -45 0 0 -225z"/>
<path d="M3710 1980 c0 -55 -2 -100 -4 -100 -2 0 -17 7 -32 15 -101 52 -214
-52 -183 -169 23 -86 109 -128 181 -88 30 16 38 17 38 6 0 -10 13 -14 45 -14
l45 0 0 225 0 225 -45 0 -45 0 0 -100z m-20 -170 c24 -24 26 -57 4 -88 -31
-45 -114 -14 -114 43 0 32 33 65 65 65 14 0 34 -9 45 -20z"/>
<path d="M260 1850 l0 -220 125 0 125 0 0 45 0 45 -75 0 -75 0 0 175 0 175
-50 0 -50 0 0 -220z"/>
<path d="M2637 2052 c-10 -10 -17 -28 -17 -39 0 -23 30 -53 53 -53 21 0 57 35
57 55 0 21 -36 55 -58 55 -11 0 -26 -8 -35 -18z"/>
<path d="M2430 1950 c0 -43 -3 -50 -20 -50 -16 0 -20 -7 -20 -35 0 -28 4 -35
19 -35 16 0 19 -10 23 -81 6 -101 24 -124 97 -124 27 0 52 3 56 7 10 10 -15
79 -26 72 -22 -13 -39 19 -39 72 0 53 0 54 30 54 28 0 30 3 30 35 0 32 -2 35
-30 35 -29 0 -30 1 -30 50 l0 50 -45 0 -45 0 0 -50z"/>
<path d="M4250 1950 c0 -43 -3 -50 -20 -50 -16 0 -20 -7 -20 -35 0 -28 4 -35
20 -35 18 0 20 -7 20 -70 0 -38 5 -80 11 -94 15 -33 59 -50 105 -41 34 6 36 8
30 37 -3 17 -6 34 -6 39 0 5 -10 8 -22 6 -21 -2 -23 2 -26 61 l-3 62 31 0 c28
0 30 3 30 35 0 32 -2 35 -30 35 -29 0 -30 1 -30 50 l0 50 -45 0 -45 0 0 -50z"/>
<path d="M643 1899 c-69 -20 -114 -96 -98 -168 23 -104 184 -145 270 -70 126
111 -2 287 -172 238z m107 -89 c40 -40 11 -110 -47 -110 -55 0 -81 59 -47 108
19 28 67 29 94 2z"/>
<path d="M1035 1893 c-20 -11 -30 -12 -33 -4 -2 6 -24 11 -48 11 l-44 0 0
-135 0 -135 45 0 45 0 0 79 c0 64 4 83 21 105 23 29 50 33 73 10 12 -12 16
-37 16 -105 l0 -89 45 0 45 0 0 106 c0 58 -5 114 -11 126 -12 22 -67 48 -101
48 -13 -1 -36 -8 -53 -17z"/>
<path d="M1324 1896 c-99 -43 -98 -220 1 -262 44 -18 82 -18 118 0 26 14 27
14 27 -10 0 -55 -64 -80 -119 -48 -25 15 -30 15 -59 0 -40 -20 -40 -28 -2 -60
70 -58 217 -39 255 34 12 22 15 66 15 190 l0 160 -45 0 c-25 0 -45 -4 -45 -9
0 -6 -16 -3 -35 5 -42 17 -71 17 -111 0z m128 -83 c39 -36 12 -113 -41 -113
-32 0 -44 8 -60 37 -15 30 -14 36 10 67 24 30 63 34 91 9z"/>
<path d="M1844 1894 c-39 -19 -74 -80 -74 -128 0 -53 33 -110 74 -130 42 -20
67 -20 114 -1 24 10 38 12 40 5 2 -5 24 -10 48 -10 l44 0 0 135 0 135 -50 0
c-27 0 -50 -4 -50 -9 0 -6 -16 -3 -35 5 -44 18 -72 18 -111 -2z m135 -90 c12
-15 21 -32 21 -39 0 -7 -9 -24 -21 -39 -38 -48 -109 -24 -109 37 0 65 70 91
109 41z"/>
<path d="M2212 1899 c-46 -14 -73 -69 -52 -106 8 -15 35 -37 60 -49 25 -13 45
-29 45 -36 0 -19 -43 -22 -70 -4 -22 14 -24 14 -40 -10 -23 -35 -14 -51 36
-64 48 -13 115 -6 142 15 25 18 36 64 24 99 -7 21 -24 34 -63 52 -30 13 -54
28 -54 33 0 15 27 20 60 11 27 -8 32 -6 45 20 14 28 14 28 -17 39 -36 13 -70
13 -116 0z"/>
<path d="M2915 1895 c-19 -10 -31 -12 -33 -6 -2 6 -24 11 -48 11 l-44 0 0
-135 0 -135 45 0 45 0 0 90 c0 84 2 91 25 106 30 20 63 10 76 -23 5 -13 9 -58
9 -99 l0 -74 45 0 45 0 0 94 c0 52 -4 106 -10 120 -19 52 -102 79 -155 51z"/>
<path d="M3194 1894 c-39 -19 -74 -80 -74 -128 0 -108 99 -172 203 -131 27 11
23 -40 -5 -59 -29 -20 -63 -20 -97 0 -25 15 -30 15 -59 0 -38 -20 -39 -26 -9
-53 69 -63 216 -50 264 24 14 21 18 56 21 190 l4 163 -51 0 c-28 0 -51 -4 -51
-9 0 -6 -16 -3 -35 5 -44 18 -72 18 -111 -2z m139 -95 c45 -61 -38 -133 -95
-82 -23 20 -23 71 -1 95 24 27 71 20 96 -13z"/>
<path d="M3924 1894 c-39 -19 -74 -80 -74 -128 0 -53 33 -110 74 -130 42 -20
67 -20 114 -1 24 10 38 12 40 5 2 -5 24 -10 48 -10 l44 0 0 135 0 135 -50 0
c-27 0 -50 -4 -50 -9 0 -6 -16 -3 -35 5 -44 18 -72 18 -111 -2z m135 -90 c12
-15 21 -32 21 -39 0 -7 -9 -24 -21 -39 -38 -48 -109 -24 -109 37 0 65 70 91
109 41z"/>
<path d="M4503 1895 c-57 -25 -90 -101 -72 -169 23 -86 109 -128 181 -88 30
16 38 17 38 6 0 -10 13 -14 45 -14 l45 0 0 135 0 135 -44 0 c-24 0 -46 -5 -48
-11 -2 -6 -14 -5 -33 5 -37 19 -70 20 -112 1z m127 -85 c24 -24 26 -57 4 -88
-31 -45 -114 -14 -114 43 0 32 33 65 65 65 14 0 34 -9 45 -20z"/>
<path d="M2630 1765 l0 -135 45 0 45 0 0 135 0 135 -45 0 -45 0 0 -135z"/>
<path d="M3770 1421 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M3900 1360 c0 -40 4 -70 10 -70 6 0 10 18 10 40 0 28 4 40 14 40 16
0 26 -21 26 -56 0 -13 5 -24 10 -24 15 0 12 63 -4 85 -8 11 -19 16 -30 12 -12
-5 -16 0 -16 18 0 14 -4 25 -10 25 -6 0 -10 -30 -10 -70z"/>
<path d="M4040 1377 c0 -52 20 -100 36 -84 4 4 2 13 -4 19 -7 7 -12 36 -12 65
0 29 -4 53 -10 53 -5 0 -10 -24 -10 -53z"/>
<path d="M2870 1355 l0 -65 33 0 c41 0 67 26 67 67 0 38 -27 63 -68 63 l-32 0
0 -65z m68 33 c27 -27 7 -78 -30 -78 -15 0 -18 8 -18 45 0 37 3 45 18 45 10 0
23 -5 30 -12z"/>
<path d="M3820 1393 c-10 -12 -11 -18 -2 -21 7 -2 12 -19 12 -38 0 -34 22 -56
38 -39 4 4 1 10 -5 12 -17 6 -17 60 -1 65 9 3 8 9 -4 21 -20 20 -19 20 -38 0z"/>
<path d="M3011 1376 c-31 -37 9 -103 47 -80 36 23 23 94 -18 94 -10 0 -23 -6
-29 -14z m44 -36 c0 -16 -6 -26 -17 -28 -14 -3 -18 3 -18 28 0 25 4 31 18 28
11 -2 17 -12 17 -28z"/>
<path d="M3150 1339 c0 -57 17 -66 22 -11 2 25 8 37 18 37 10 0 16 -12 18 -37
4 -51 22 -49 22 2 0 29 4 40 15 40 11 0 15 -11 15 -40 0 -22 5 -40 10 -40 6 0
10 20 10 45 0 48 -18 67 -44 46 -7 -6 -16 -8 -20 -4 -4 4 -21 8 -37 9 -28 2
-29 0 -29 -47z"/>
<path d="M3321 1376 c-31 -37 9 -103 47 -80 36 23 23 94 -18 94 -10 0 -23 -6
-29 -14z m44 -36 c0 -16 -6 -26 -17 -28 -14 -3 -18 3 -18 28 0 25 4 31 18 28
11 -2 17 -12 17 -28z"/>
<path d="M3420 1339 c0 -27 5 -49 10 -49 6 0 10 16 10 35 0 22 6 38 15 41 23
9 18 22 -10 22 -23 0 -25 -4 -25 -49z"/>
<path d="M3502 1378 c-7 -7 -12 -24 -12 -38 0 -45 43 -67 70 -36 10 13 7 15
-16 13 -15 -1 -29 4 -31 11 -3 8 6 12 26 12 19 0 31 5 31 13 0 18 -21 37 -40
37 -9 0 -21 -5 -28 -12z m48 -18 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6
13 10 21 10 8 0 14 -4 14 -10z"/>
<path d="M3635 1349 c12 -70 26 -79 42 -26 10 33 13 31 29 -20 7 -26 22 -12
33 31 13 54 13 56 2 56 -5 0 -12 -15 -16 -32 l-7 -33 -11 33 c-12 40 -26 41
-33 2 -8 -36 -24 -40 -24 -6 0 14 -5 28 -11 32 -8 5 -9 -6 -4 -37z"/>
<path d="M3770 1340 c0 -27 5 -50 10 -50 6 0 10 23 10 50 0 28 -4 50 -10 50
-5 0 -10 -22 -10 -50z"/>
<path d="M4106 1374 c-21 -20 -20 -48 0 -69 19 -18 55 -17 62 3 3 9 -3 11 -22
6 -20 -5 -26 -2 -26 10 0 11 9 16 30 16 24 0 28 3 23 18 -13 34 -42 41 -67 16z
m49 -14 c4 -6 -5 -10 -20 -10 -15 0 -24 4 -20 10 3 6 12 10 20 10 8 0 17 -4
20 -10z"/>
<path d="M4207 1383 c-14 -13 -6 -42 13 -48 26 -8 26 -29 0 -22 -11 3 -22 1
-25 -4 -7 -11 40 -23 54 -14 21 12 11 45 -16 57 l-28 12 28 4 c15 2 25 8 22
13 -7 10 -38 12 -48 2z"/>
<path d="M4291 1381 c-18 -12 -7 -41 19 -48 29 -8 24 -26 -5 -20 -16 3 -24 0
-22 -7 6 -17 42 -19 57 -1 10 12 10 18 0 30 -7 8 -19 15 -26 15 -8 0 -14 5
-14 10 0 6 9 10 21 10 11 0 17 5 14 10 -8 12 -25 13 -44 1z"/>
<path d="M2663 1356 c16 -13 16 -14 1 -20 -9 -4 -14 -9 -11 -12 8 -8 48 16 42
25 -2 5 -15 11 -27 14 -21 6 -22 5 -5 -7z"/>
<path d="M2733 1356 c18 -14 18 -15 0 -26 -15 -9 -15 -10 7 -4 14 3 27 9 30
11 6 6 -31 33 -44 33 -6 0 -3 -7 7 -14z"/>
<path d="M2803 1356 c18 -14 18 -15 0 -25 -24 -14 -12 -14 24 0 l28 11 -25 13
c-32 18 -50 18 -27 1z"/>
<path d="M4370 1305 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M4420 1305 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M4466 1312 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
